interface Vector {
  x: number;
  y: number;
}

const add = function (v1: Vector, v2: Vector): Vector {
  return { x: v1.x + v2.x, y: v1.y + v2.y };
}

const v1 = {x: 1, y: 2}
const v2 = {x: 4, y: 2}

console.log(add(v1, v2));
